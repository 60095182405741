import { Controller } from "@hotwired/stimulus";
class src_default extends Controller {
  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }
  connect() {
    if (!this.hasCheckboxAllTarget)
      return;
    this.checkboxAllTarget.addEventListener("change", this.toggle);
    this.checkboxTargets.forEach((checkbox) => checkbox.addEventListener("change", this.refresh));
    this.refresh();
  }
  disconnect() {
    if (!this.hasCheckboxAllTarget)
      return;
    this.checkboxAllTarget.removeEventListener("change", this.toggle);
    this.checkboxTargets.forEach((checkbox) => checkbox.removeEventListener("change", this.refresh));
  }
  toggle(e) {
    e.preventDefault();
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked;
      this.triggerInputEvent(checkbox);
    });
  }
  refresh() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;
    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
    this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;
  }
  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true });
    checkbox.dispatchEvent(event);
  }
  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }
  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
  }
}
src_default.targets = ["checkboxAll", "checkbox"];
export { src_default as default };
